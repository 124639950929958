// import React from "react";
// import styled from "styled-components";
// import {
//   BrowserRouter as Router,
//   Route,
//   Link,
//   Routes,
//   useNavigate,
// } from "react-router-dom";
// // Styled components
// const NavContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
//   background-color: #fff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const LeftSection = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const Icon = styled.img`
//   width: 350px;
//   height: 50px;
//   object-fit: contain;
//   //   margin-right: 10px;
// `;

// const Text = styled.span`
//   font-size: 14px;
//   color: #1b3b80;
//   font-weight: bold;
// `;

// const RightSection = styled.div``;

// const RegisterButton = styled.button`
//   padding: 10px 20px;
//   font-size: 14px;
//   color: #1b3b80;
//   border: 2px solid #1b3b80;
//   background-color: transparent;
//   border-radius: 20px;
//   cursor: pointer;

//   &:hover {
//     background-color: #1b3b80;
//     color: white;
//   }
// `;

// const Navbar = ({ isAdmin }) => {
//   const navigate = useNavigate();
//   return (
//     <NavContainer>
//       <LeftSection>
//         <Icon src="../../logo.png" alt="NCRP Logo" />
//       </LeftSection>
//       <RightSection>
//         {isAdmin ? (
//           <RegisterButton
//             onClick={() => {
//               localStorage.removeItem("NCRPadmin");
//               navigate("/login");
//             }}
//           >
//             Admin Logout
//           </RegisterButton>
//         ) : (
//           <RegisterButton
//             onClick={() => {
//               navigate("/register");
//             }}
//           >
//             Register Now
//           </RegisterButton>
//         )}
//       </RightSection>
//     </NavContainer>
//   );
// };

// export default Navbar;

import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex: 1;
    max-width: 70%;
  }
`;

const Icon = styled.img`
  width: 350px;
  height: 50px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 100%;
    height: 40px;
  }
`;

const Text = styled.span`
  font-size: 14px;
  color: #1b3b80;
  font-weight: bold;
`;

const RightSection = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
  }
`;

const RegisterButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  color: #1b3b80;
  border: 2px solid #1b3b80;
  background-color: transparent;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #1b3b80;
    color: white;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;

const Navbar = ({ isAdmin }) => {
  const navigate = useNavigate();
  return (
    <NavContainer>
      <LeftSection>
        <Icon src="../../logo.png" alt="NCRP Logo" />
      </LeftSection>
      <RightSection>
        {isAdmin ? (
          <RegisterButton
            onClick={() => {
              localStorage.removeItem("NCRPadmin");
              navigate("/login");
            }}
          >
            Admin Logout
          </RegisterButton>
        ) : (
          <RegisterButton
            onClick={() => {
              navigate("/register");
            }}
          >
            Register Now
          </RegisterButton>
        )}
      </RightSection>
    </NavContainer>
  );
};

export default Navbar;
