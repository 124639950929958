import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Payment from "././paypal";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import { US_STATES, CITIES_BY_STATE } from "./data";
import Modal from "./modal";
import SuccessModal from "./successModal";
import FailModal from "./failModal";

const FormWrapper = styled.div`
  background-image: url("../../form_bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
`;

const FormContainer = styled.div`
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ddd;
  // border-radius: 4px;
`;

const Select = styled.select`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const RadioGroup = styled.div`
  margin-bottom: 15px;
`;

const RadioLabel = styled.label`
  margin-right: 15px;
`;

const HighlightedSection = styled.div`
  background-color: #e6f3ff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
`;

const InfoIcon = styled.span`
  color: #ff9800;
  margin-left: 5px;
`;

const RequiredFee = styled.p`
  font-weight: bold;
  margin-bottom: 15px;
`;

const SubmitButton = styled.button`
  background-color:  ${(props) => (props.disabled ? '#d3d3d3' : '#0056b3')};
  color: ${(props) => (props.disabled ? '#808080' : 'white')};;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor:${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
`;

const DonationButton = styled(SubmitButton)`
  margin: 3px;
  width: 100px;
  background-color: ${(props) =>
    props.active ? "rgba(245, 130, 49, 1)" : "#0056b3"};

  &:hover {
    background-color: rgba(245, 130, 49, 1);
  }
`;

const DonationInput = styled(Input)`
  width: 40%;
  margin-right: 10px;
  border: none;
  border-bottom: 2px solid #000; // Only bottom border
  text-align: center;
  display: block;

  margin: 0 0 6% 30%; // Centers the input horizontally
  outline: none; // Removes outline when focused (optional)
  &:focus {
    border-bottom-color: rgba(
      28,
      57,
      157,
      1
    ); // Optional: border color on focus
  }
`;

const DonationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8em;
  margin-top: -10px;
  margin-bottom: 10px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* This ensures the elements wrap to a new line if necessary */
  justify-content: space-between; /* This distributes the elements evenly within the container */
`;

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [Cities, setCities] = useState([]);
  const[openFailModal, setFailModalOpen] = useState(false);
  const[openSuccessModal, setSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failMessage, setFailMessage] = useState(null);
  const [formData, setFormData] = useState({
    prefix: "",
    firstName: "",
    lastName: "",
    affiliation: "",
    email: "",
    address: "",
    mobilePhone: "",
    isCouncilMember: "",
    attendDinner: "",
    donation: 0,
    orderID: "",
    paymentID: "",
    payerID: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (step === 1) {
      setFailMessage(null);
    }
  }, [step]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const validateCardNumber = (cardNumber) => {
    const re = /^\d{16}$/;
    return re.test(String(cardNumber));
  };

  const validateCardExpiry = (expiry) => {
    //const re = /^(0[1-9]|1[0-2])\/\d{2}$/;
    return true; //re.test(String(expiry));
  };

  const validateCardCvv = (cvv) => {
    const re = /^\d{3,4}$/;
    return re.test(String(cvv));
  };

  const validateZipCode = (zipCode) => {
    const re = /^\d{5}(-\d{4})?$/;
    return re.test(String(zipCode));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // set city against the state
    if (name === "state") setCities(value ? CITIES_BY_STATE[value] || [] : []);

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleDonationClick = (amount, index) => {
    setFormData((prevState) => ({
      ...prevState,
      donation: amount,
    }));
    setActiveButton(index);
  };

  const validateStep = (step) => {
    const newErrors = {};

    if (step === 1) {
      if (!formData.email) newErrors.email = "Email is required";
      else if (!validateEmail(formData.email))
        newErrors.email = "Invalid email format";
      if (!formData.mobilePhone)
        newErrors.mobilePhone = "Phone number is required";
      else if (!validatePhone(formData.mobilePhone))
        newErrors.mobilePhone = "Invalid phone number";
      if (!formData.isCouncilMember)
        newErrors.isCouncilMember = "Please select if you are a council member.";
      if (formData.isCouncilMember === "true" && !formData.attendDinner) {
        newErrors.attendDinner = "Please indicate your attendance for the dinner.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // const handleModalClose = () => {
  //   setFailModalOpen(false);
  //   setSuccessModalOpen(false);
  // }
  const handlePayment = async (
    status,
    payerID,
    paymentID,
    orderID,
    facilitatorAccessToken
  ) => {
    if(status){
      setSuccessMessage("Your payment was successful! Please click the 'Register Now' button to complete your registration")
      setFailMessage(null);
    } else {
      setFailMessage("Your payment failed. Please try again or contact support for assistance.")
      setSuccessMessage(null);
    }
    setFormData((prevState) => ({
      ...prevState,
      payerID: paymentID,
      paymentID: payerID,
      orderID: orderID,
      facilitatorAccessToken: facilitatorAccessToken,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step < 3) {
      if (validateStep(step)) {
        setStep(step + 1);
      }
    } else {
      if (validateStep(step)) {
        try {
          const data = {
            email: formData.email, // formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            prefix: formData.prefix,
            affiliation: formData.affiliation,
            address: formData.address,
            phone: formData.mobilePhone,
            is_council_member:
              formData.isCouncilMember === "true" ? true : false,
            attending_dinner: formData.attendDinner === "true" ? true : false,
            amount: (175 + parseInt(formData.donation || 0)).toFixed(2),
            donation: formData.donation.toFixed(2),
            orderID: formData.orderID,
            paymentID: formData.paymentID,
            payerID: formData.payerID,
            facilitatorAccessToken: formData.payerID,
          };
          const response = await fetch(
            "https://event-backend.ncrptesting.com/api/process-transaction",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          if (response.ok) {
            const result = await response.json();
            
            // setSuccessModalOpen(true);
            // setFailModalOpen(false);
            setStep(4);
            setSuccessMessage(result?.message)
            setFailMessage(null);
            setFormData({
              prefix: "",
              firstName: "",
              lastName: "",
              affiliation: "",
              email: "",
              address: "",
              mobilePhone: "",
              isCouncilMember: "",
              attendDinner: "",
              donation: 0,
              orderID: "",
              paymentID: "",
              payerID: "",
            })
          } else {
            // setFailModalOpen(true);
            // setSuccessModalOpen(false);
            setStep(4);
            setFailMessage("Registration failed. Please try again. We are processing your refund.")
            setSuccessMessage(null);
          }
        } catch (error) {
          // setFailModalOpen(true);
          // setSuccessModalOpen(false);
          setStep(4);
          setFailMessage("Registration failed. Please try again. We are processing your refund.");
          setSuccessMessage(null);
        }
      }
    }
  };

  const renderStep1 = () => (
    <>
      <Title>Registration Form</Title>
      <Input
        type="text"
        name="prefix"
        placeholder="Prefix"
        onChange={handleChange}
        value={formData.prefix}
        required
      />
      <Input
        type="text"
        name="firstName"
        placeholder="First Name"
        onChange={handleChange}
        value={formData.firstName}
        required
      />
      {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
      <Input
        type="text"
        name="lastName"
        placeholder="Last Name"
        onChange={handleChange}
        value={formData.lastName}
        required
      />
      {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
      <Input
        type="text"
        name="affiliation"
        placeholder="Affiliation"
        onChange={handleChange}
        value={formData.affiliation}
        required
      />
      <Input
        type="email"
        name="email"
        placeholder="Email address"
        onChange={handleChange}
        value={formData.email}
        required
      />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <Input
        type="text"
        name="address"
        placeholder="Address"
        onChange={handleChange}
        value={formData.address}
        required
      />
      {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
      <Input
        type="tel"
        name="mobilePhone"
        placeholder="Mobile Phone"
        onChange={handleChange}
        value={formData.mobilePhone}
        required
      />
      {errors.mobilePhone && <ErrorMessage>{errors.mobilePhone}</ErrorMessage>}
      <RadioGroup>
        <p>Are you an NCRP Council Member or Emeritus?</p>
        <RadioLabel>
          <input
            type="radio"
            name="isCouncilMember"
            value="true"
            onChange={handleChange}
            checked={formData.isCouncilMember === "true"}
          />{" "}
          Yes
        </RadioLabel>
        <RadioLabel>
          <input
            type="radio"
            name="isCouncilMember"
            value="false"
            onChange={handleChange}
            checked={formData.isCouncilMember === "false"}
          />{" "}
          No
        </RadioLabel>
      </RadioGroup>
      {errors.isCouncilMember && (
    <ErrorMessage>{errors.isCouncilMember}</ErrorMessage>
  )}
      {formData.isCouncilMember === "true" ? (
        <>
    <HighlightedSection>
          <p>
            Will you attend the Members' Reception dinner on Sunday Evening,
            March 24th?
          </p>
          <RadioLabel>
            <input
              type="radio"
              name="attendDinner"
              value="true"
              onChange={handleChange}
              checked={formData.attendDinner === "true"}
            />{" "}
            Yes
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="attendDinner"
              value="false"
              onChange={handleChange}
              checked={formData.attendDinner === "false"}
            />{" "}
            No
          </RadioLabel>
          <InfoIcon>ⓘ</InfoIcon>
        </HighlightedSection>
        {errors.attendDinner && <ErrorMessage>{errors.attendDinner}</ErrorMessage>}
        </>
      ) : (
        ""
      )}
      <RequiredFee>Required Fee: $175</RequiredFee>
    </>
  );
  const [activeButton, setActiveButton] = useState(null);
  const renderStep2 = () => (
    <>
      <Title>Donation</Title>
      <p>
        National Council on Radiation Protection & Measurements (NCRP) is
        dedicated to improving radiation protection. Your donation helps us
        fulfill that mission and supports our vital research and initiatives.
        Join us in this vital mission by contributing to the NCRP. Your donation
        today will help move us forward in advancing public health outcomes.
        Join us in this vital mission to empower radiation safety. Please donate
        to our cause today and make a lasting impact.
      </p>
      <DonationInput
        type="number"
        name="donation"
        placeholder="$"
        onChange={handleChange}
        value={formData.donation}
      />
      <DonationRow>
        <DonationButton
          type="button"
          active={activeButton === 0}
          onClick={() => handleDonationClick(200, 0)}
        >
          $200
        </DonationButton>
        <DonationButton
          type="button"
          active={activeButton === 1}
          onClick={() => handleDonationClick(100, 1)}
        >
          $100
        </DonationButton>
        <DonationButton
          type="button"
          active={activeButton === 2}
          onClick={() => handleDonationClick(50, 2)}
        >
          $50
        </DonationButton>
      </DonationRow>
      <DonationRow>
        <DonationButton
          type="button"
          active={activeButton === 3}
          onClick={() => handleDonationClick(25, 3)}
        >
          $25
        </DonationButton>
        <DonationButton
          type="button"
          active={activeButton === 4}
          onClick={() => handleDonationClick(10, 4)}
        >
          $10
        </DonationButton>
        <DonationButton
          style={{ fontSize: "11px" }}
          type="button"
          onClick={() => handleDonationClick(0, 5)}
          active={activeButton === 5}
        >
          Your Donation
        </DonationButton>
      </DonationRow>
      <RequiredFee>Registration Fee: $175</RequiredFee>
      <TotalRow>
        <span>Total</span>
        <span>${175 + parseInt(formData.donation || 0)}</span>
      </TotalRow>
    </>
  );

  const renderStep3 = () => (
    <Payment
      amount={175 + parseInt(formData.donation || 0)}
      handlePayment={handlePayment}
    />
  );
  const renderStep4 = () => (
    <>
      <Title>{successMessage ? "Success" : "Error"}</Title>
      {successMessage && <p style={{ color: "green", textAlign: "center" }}>{successMessage}</p>}
      {failMessage && <p style={{ color: "red", textAlign: "center" }}>{failMessage}</p>}
      <SubmitButton
         onClick={() => {
          if (successMessage) {
            navigate("/");
          } else {
            setStep(1);
          }
        }}
      >
        {successMessage ? "Go to Home" : "Try Again"}
      </SubmitButton>
    </>
  );
  const navigate = useNavigate();
  return (
    <>
    {/* <SuccessModal isOpen={openSuccessModal} message={successMessage} onClose={handleModalClose}/>
    <FailModal isOpen={openFailModal} message={failMessage} onClose={handleModalClose}/> */}
    <FormWrapper>
      <FormContainer>
        {/* <CloseButton
          onClick={() =>
            step === 2 ? setStep(1) : step === 3 ? setStep(2) : navigate("/")
          }
        >
          ×
        </CloseButton> */}
        <Form onSubmit={handleSubmit}>
          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}
          {step === 4 && renderStep4()}
          {step !== 4 && (
            <>
            {successMessage && <p style={{ color: "green", textAlign: "center" }}>{successMessage}</p>}
            {failMessage && <p style={{ color: "red", textAlign: "center" }}>{failMessage}</p>}
            <SubmitButton disabled={step === 3 && !successMessage} type="submit">
            {step === 3 ? "Register Now" : "Enter"}
            </SubmitButton>
          <SubmitButton
            style={{ margin: "2% 0 0 0" }}
            type="button" // Change this to "button" to prevent form submission
            onClick={() =>
                step === 2 ? setStep(1) : step === 3 ? setStep(2) : navigate("/")
                  }
          >
            {step === 1 ? "Cancel" : "Back"}
          </SubmitButton>
          </>
          )}
        </Form>
      </FormContainer>
    </FormWrapper>
    </>
    
  );
};

export default MultiStepForm;
