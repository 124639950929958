import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";

const HeroWrapper = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const BackgroundDiv = styled.div`
  position: relative;
  width: 100%;
  background-image: url("../../intro_bg.png");
  background-size: cover;
  padding: 2rem 1rem;
  min-height: 70vh;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @media (max-width: 768px) {
      height: 0%;
    }
    height: 30%;
    background-color: white;
    z-index: 1;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 1rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const TextContent = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-right: 150px;

  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 60px; // Space for the button below
    text-align: center;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: bold;
  max-width: 600px;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: clamp(2rem, 4vw, 2.5rem);
    max-width: 100%;
    margin: 0 auto;
  }
`;

const DonateButton = styled.button`
  background-color: #2a3b8f;
  color: white;
  padding: 12px 32px;
  border-radius: 50px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    background-color: #1e2b6a;
  }

  @media (max-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: 0;
    width: 200px; // Fixed width on mobile
    padding: 10px 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  position: relative;
  z-index: 2;
  max-width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin: 1rem auto;
    border-radius: 8px;
    width: calc(100% - 2rem); // Account for padding
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`;

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <HeroWrapper>
      <BackgroundDiv>
        <Container>
          <TextContent>
            <Title>YOU HAVE THE POWER TODAY TO CHANGE TOMORROW</Title>
            <DonateButton
              onClick={() => {
                navigate("/register");
              }}
            >
              Donate Now
            </DonateButton>
          </TextContent>
          <ImageContainer>
            <Image
              src="../../intro.png"
              alt="Conference audience listening to presentation"
            />
          </ImageContainer>
        </Container>
      </BackgroundDiv>
    </HeroWrapper>
  );
};

export default HeroSection;
