import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledA1,
  StyledA2,
  StyledA3,
  StyledButonSec,
  StyledButton,
  StyledDiv,
  StyledH2,
  StyledIcon,
  StyledIcons,
  IconFirst,
  StyledIconSec,
  StyledLine,
  StyledList,
  StyledListItems,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP2,
  StyledP3,
  StyledRow,
  StyledSectionA,
  StyledSectionB,
  StyledTag,
  StyledText,
  StyledTitle,
  StyledFirst,
  StyledSecond,
  StyledThird,
  StyledBoth,
} from "./style";
import { truncateText } from "../../utils/helper";

export function Footer({
  showTag,
  height,
  xxlHeight,
  smallHeight,
  smallerHeight,
  mobileHeight,
  xsHeight,
  margin,
  marginB,
}) {
  const navigate = useNavigate();
  // const { useJobPostsList } = useJobManagement();
  // const { jobPosts, loading, error } = useJobPostsList();
  const [latestJobPost, setLatestJobPost] = useState(null);

  //console.log("Job post:L", jobPosts);
  // useEffect(() => {
  //   if (jobPosts.length > 0) {
  //     const activeJobPosts = jobPosts.filter(
  //       (post) => post.status === "active"
  //     );
  //     if (activeJobPosts.length > 0) {
  //       const sortedPosts = activeJobPosts.sort(
  //         (a, b) => new Date(b.posting_date) - new Date(a.posting_date)
  //       );
  //       setLatestJobPost(sortedPosts[0]);
  //     }
  //   }
  // }, [jobPosts]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  return (
    <StyledMain
      height={height}
      xxlHeight={xxlHeight}
      smallHeight={smallHeight}
      smallerHeight={smallerHeight}
      mobileHeight={mobileHeight}
      xsHeight={xsHeight}
    >
      {showTag && latestJobPost && (
        <StyledTag>
          <StyledText>
            <StyledH2>We are hiring</StyledH2>
            <StyledP1>{latestJobPost.title}</StyledP1>
            <StyledP2>{truncateText(latestJobPost.description, 50)}</StyledP2>
          </StyledText>
          <StyledButonSec>
            <StyledButton onClick={() => navigate("/jobs")}>
              Apply Now
            </StyledButton>
          </StyledButonSec>
        </StyledTag>
      )}
      <StyledDiv margin={margin} marginB={marginB}>
        <StyledSectionA>
          <StyledFirst>
            <StyledA1>
              <StyledIconSec>
                <StyledIcon src="../../logo_white.png" />
                {/* <StyledH4>
                  National Council on Radiation Protection and Measurements
                </StyledH4> */}
              </StyledIconSec>
              <StyledP>
                7910 Woodmont Avenue, Suite 905, Bethesda, MD 20814-3046
              </StyledP>
              <StyledP>
                (301) 657-2652 (301) 560-8005
                <span>ncrp@ncrponline.org</span>
              </StyledP>
              <StyledIcons>
                <a
                  href="https://twitter.com/NCRP_USA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconFirst>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="41"
                      height="42"
                      viewBox="0 0 41 42"
                      fill="none"
                    >
                      <circle cx="20.5" cy="20.625" r="20" stroke="white" />
                      <path
                        d="M29.46 14.13c-.81.36-1.68.6-2.59.71.93-.56 1.64-1.44 1.98-2.49-.87.52-1.84.89-2.87 1.1a4.54 4.54 0 00-7.73 4.14c-3.77-.19-7.11-2-9.35-4.77-.39.66-.61 1.43-.61 2.24 0 1.55.79 2.92 2 3.73-.73-.02-1.42-.23-2.02-.56v.06c0 2.17 1.55 3.98 3.61 4.39-.38.1-.79.16-1.21.16-.29 0-.57-.03-.85-.08.57 1.78 2.23 3.08 4.2 3.12a9.11 9.11 0 01-5.64 1.94c-.37 0-.73-.02-1.09-.06a12.87 12.87 0 006.97 2.04c8.37 0 12.95-6.93 12.95-12.95v-.59c.88-.64 1.63-1.44 2.23-2.36z"
                        fill="white"
                      />
                    </svg>
                  </IconFirst>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCdDER8FkZYQToU9pmL9MT5Q"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconFirst>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="41"
                      height="42"
                      viewBox="0 0 41 42"
                      fill="none"
                    >
                      <circle cx="20.5" cy="20.625" r="20" stroke="white" />
                      <path
                        d="M18 23.125L23.19 20.125L18 17.125V23.125ZM29.56 15.295C29.69 15.765 29.78 16.395 29.84 17.195C29.91 17.995 29.94 18.685 29.94 19.285L30 20.125C30 22.315 29.84 23.925 29.56 24.955C29.31 25.855 28.73 26.435 27.83 26.685C27.36 26.815 26.5 26.905 25.18 26.965C23.88 27.035 22.69 27.065 21.59 27.065L20 27.125C15.81 27.125 13.2 26.965 12.17 26.685C11.27 26.435 10.69 25.855 10.44 24.955C10.31 24.485 10.22 23.855 10.16 23.055C10.09 22.255 10.06 21.565 10.06 20.965L10 20.125C10 17.935 10.16 16.325 10.44 15.295C10.69 14.395 11.27 13.815 12.17 13.565C12.64 13.435 13.5 13.345 14.82 13.285C16.12 13.215 17.31 13.185 18.41 13.185L20 13.125C24.19 13.125 26.8 13.285 27.83 13.565C28.73 13.815 29.31 14.395 29.56 15.295Z"
                        fill="white"
                      />
                    </svg>
                  </IconFirst>
                </a>

                <a
                  href="mailto:ncrp@ncrponline.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconFirst>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="41"
                      height="42"
                      viewBox="0 0 41 42"
                      fill="none"
                    >
                      <circle cx="20.5" cy="20.625" r="20" stroke="white" />
                      <path
                        d="M13.5 11.125C12.5717 11.125 11.6815 11.5043 11.0251 12.1794C10.3687 12.8545 10 13.7702 10 14.725V15.0868L20.5 20.9026L31 15.0886V14.725C31 13.7702 30.6313 12.8545 29.9749 12.1794C29.3185 11.5043 28.4283 11.125 27.5 11.125H13.5ZM31 17.1316L20.9148 22.717C20.7873 22.7876 20.6448 22.8245 20.5 22.8245C20.3552 22.8245 20.2127 22.7876 20.0852 22.717L10 17.1316V25.525C10 26.4798 10.3687 27.3955 11.0251 28.0706C11.6815 28.7457 12.5717 29.125 13.5 29.125H27.5C28.4283 29.125 29.3185 28.7457 29.9749 28.0706C30.6313 27.3955 31 26.4798 31 25.525V17.1316Z"
                        fill="white"
                      />
                    </svg>
                  </IconFirst>
                </a>
              </StyledIcons>
            </StyledA1>
          </StyledFirst>
          <StyledSecond>
            <StyledBoth>
              <StyledA2>
                <StyledTitle>Helpful Links</StyledTitle>
                <StyledList>
                  <a
                    href="https://ncrponline.org/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>About</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/publications/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Publications</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/program-areas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Program Areas</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/news-events/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>News & Events</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/contact-us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Contact</StyledListItems>
                  </a>
                </StyledList>
              </StyledA2>
              <StyledA2>
                <StyledTitle>Related Organizations</StyledTitle>
                <StyledList>
                  <a
                    href="https://ncrponline.org/related-organizations/collaborating-organizations/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Collaborating</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/related-organizations/special-liaison-organizations/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Special Liaison</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/related-organizations/contributing-organizations/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Contributors</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/related-organizations/corporate-sponsors/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Sponsors</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/related-organizations/other-related-organizations/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Others</StyledListItems>
                  </a>
                </StyledList>
              </StyledA2>
            </StyledBoth>
            <StyledThird>
              <StyledA3>
                <StyledTitle>Quick Links</StyledTitle>
                <StyledList>
                  <a
                    href="https://ncrponline.org/publications/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>Order Publications Online</StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/wp-content/themes/ncrp/PDFs/Abb-Acronyms.pdf"
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>
                      Common Abbreviations & Acronyms
                    </StyledListItems>
                  </a>
                  <a
                    href="https://ncrponline.org/wp-content/themes/ncrp/PDFs/NCRP-Composite-Glossary.pdf"
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledListItems>
                      Composite Glossary of Radiological Terms
                    </StyledListItems>
                  </a>
                </StyledList>
              </StyledA3>
            </StyledThird>
          </StyledSecond>
        </StyledSectionA>
        <StyledLine></StyledLine>
        <StyledSectionB>
          <StyledP3>
            Copyright © 2024 National Council on Radiation Protection and
            Measurements. All Rights Reserved.
          </StyledP3>
        </StyledSectionB>
      </StyledDiv>
    </StyledMain>
  );
}

export default Footer;
