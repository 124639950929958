import { useEffect, useState } from "react";
import { FUNDING } from "@paypal/react-paypal-js";
const PayPalComponent = ({ amount, handlePayment }) => {
  const [sdkReady, setSdkReady] = useState(false); // Track SDK load status

  useEffect(() => {
    // Function to dynamically load PayPal SDK
    const Cid =
      "AYjkg-q3kDXI9CCIUb808hlnPGO_kg3pTlNUmy2nXc_AwZoHHLHdqTFTD67FVnlCBZRwH7q7qGiBjUoP";
    const loadPayPalScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${Cid}&currency=USD`;
      script.async = true;
      script.onload = () => setSdkReady(true); // Set ready state when script is loaded
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      loadPayPalScript(); // Load the script if not already loaded
    } else {
      setSdkReady(true); // SDK is already loaded
    }
  }, []);

  useEffect(() => {
    if (sdkReady) {
      window.paypal
        .Buttons({
          fundingSource: FUNDING.CARD,
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount.toString(),
                  },
                },
              ],
              application_context: {
                shipping_preference: "NO_SHIPPING", // Disable shipping address
              },
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              const orderID = data.orderID;
              const payerID = data.payerID;
              handlePayment(
                true,
                orderID,
                payerID,
                data.paymentID,
                data.facilitatorAccessToken
              );
              console.log(data);
              // Send orderID, payerID, and amount to your backend API
              //   fetch("/your-api-endpoint", {
              //     method: "POST",
              //     headers: {
              //       "Content-Type": "application/json",
              //     },
              //     body: JSON.stringify({
              //       orderID: orderID,
              //       payerID: payerID,
              //       amount: amount,
              //     }),
              //   })
              //     .then((response) => response.json())
              //     .then((data) => {
              //       console.log("Transaction successful:", data);
              //     })
              //     .catch((error) => {
              //       console.error("Transaction error:", error);
              //     });
            });
          },
        })
        .render("#paypal-button-container");
    }
  }, [sdkReady, amount]);

  return sdkReady ? (
    <div id="paypal-button-container"></div>
  ) : (
    <div>Loading PayPal...</div>
  );
};

export default PayPalComponent;
