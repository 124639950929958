import React from "react";
import Navbar from "./Nav";
import Hero from "./Hero";
import Intro from "./Intro";
import Footer from "./Footer/index";

function Index() {
  return (
    <>
      <Navbar />
      <Hero />
      <Intro />
      <Footer />
    </>
  );
}

export default Index;
