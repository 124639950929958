import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 80%;
  margin: 3% auto;
`;

const SearchBar = styled.input`
  width: 20%;
  padding: 0.5rem;
  font-size: 1rem;
  float: right;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const Loader = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem;
`;

// Define columns for DataTable
const columns = [
  { name: "Sr", selector: (row) => row.sr_no, sortable: true },
  { name: "Prefix", selector: (row) => row.prefix, sortable: true },
  { name: "First Name", selector: (row) => row.first_name, sortable: true },
  { name: "Last Name", selector: (row) => row.last_name, sortable: true },
  { name: "Email", selector: (row) => row.email, sortable: true },
  { name: "Phone", selector: (row) => row.phone, sortable: true },
  { name: "Address", selector: (row) => row.address, sortable: true },
  {
    name: "NCRP Member",
    selector: (row) => (row.is_council_member === 1 ? "Yes" : "No"),
    sortable: true,
  },
  {
    name: "Attend ",
    selector: (row) => (row.attending_dinner === 1 ? "Yes" : "No"),
    sortable: true,
  },
  { name: "Donation", selector: (row) => row.payment.donation, sortable: true },
  {
    name: "Ticket Number",
    selector: (row) => row.ticket_number,
    sortable: true,
  },
];

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgba(28, 57, 157, 1)",
      color: "white",
      fontSize:"11px",
      fontWeight: "bold",
    },
  },
};
const SearchAndTableComponent = () => {
  const [data, setData] = useState([]); // API data
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search
  const [search, setSearch] = useState(""); // Search input
  const [loading, setLoading] = useState(true); // Loader state

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter((item) =>
        Object.values(item).some((val) =>
          val?.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  }, [search, data]);

  const fetchData = async () => {
    const token = localStorage.getItem("NCRPadmin");
    try {
      const response = await fetch(
        "https://event-backend.ncrptesting.com/api/user",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const dataWithSrNo = result.data.map((item, index) => ({
        ...item,               // Spread the original object properties
        sr_no: index + 1        // Add a 'Sr no' property starting from 1
      }));
  
      // Set the updated data with Sr no into both state variables
      setData(dataWithSrNo);
      setFilteredData(dataWithSrNo);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading after fetching
    }
  };

  return (
    <PageContainer>
      <SearchBar
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {loading ? (
        <Loader>Loading...</Loader> // Show loader when data is being fetched
      ) : (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          striped
          customStyles={customStyles}
          noDataComponent="No data available" // In case filtered data is empty
        />
      )}
    </PageContainer>
  );
};

export default SearchAndTableComponent;
