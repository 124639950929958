// SuccessModal.js
import React from 'react';
import styled from 'styled-components';
import Modal from './modal';

const SuccessContent = styled.div`
  text-align: center;
  color: green;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;

const TickIcon = styled.span`
  display: block;
  width: 16px;
  height: 32px;
  border: solid white;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
`;

const SuccessModal = ({ isOpen, onClose, message }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <SuccessContent>
      <IconWrapper>
        <TickIcon />
      </IconWrapper>
      <h2>Success!</h2>
      {message && <p>{message}</p>}
    </SuccessContent>
  </Modal>
);

export default SuccessModal;
