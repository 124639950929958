import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../../form_bg.jpg");
  background-color: #f0f0f0;
`;

const FormContainer = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;

  background-size: cover;
  background-position: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
`;

const FormContent = styled.div`
  position: relative;
  z-index: 2;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #004494;
  }
`;

const CancelButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: #0056b3;
  border: none;
  cursor: pointer;
  margin-top: 0.5rem;
`;
const ErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch(
        "https://event-backend.ncrptesting.com/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      console.log("Login successful:", data);
      localStorage.setItem("NCRPadmin", data.token);
      navigate("/admin");
      // Handle successful login (e.g., store token, redirect)
    } catch (err) {
      alert("Login failed. Please check your credentials and try again.");
      console.error("Login error:", err);
      navigate("/");
    }
  };
  return (
    <PageContainer>
      <FormContainer>
        <FormContent>
          <Logo src="logo.png" alt="NCRP Logo" />
          <Title>Log in to NCRP Admin</Title>
          <p>Welcome back! Please enter your details.</p>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <RememberMeContainer>
            <Checkbox type="checkbox" id="remember-me" />
            <label htmlFor="remember-me">Remember me</label>
          </RememberMeContainer> */}
          <Button onClick={handleLogin}>Login</Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormContent>
      </FormContainer>
    </PageContainer>
  );
};

export default LoginForm;
