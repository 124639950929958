import React from "react";
import Details from "./detail";
import Navbar from "../Nav";
function Admin() {
  return (
    <>
      <Navbar isAdmin={true} />
      <Details />
    </>
  );
}

export default Admin;
