import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";

// Styled Components
const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../hero_bg.png");
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }
`;

const LeftContainer = styled.div`
  max-width: 50%;
  padding: 30px;
  margin: 0 0 0 4%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    padding: 15px;
    order: 1;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.7%;
  text-align: center;
  font-family: Montserrat, sans-serif;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.2rem;
  margin: 0 0 2% 12.2%;
  padding: 20px;
  width: 70%;
  text-align: center;
  font-family: Montserrat, sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 0;
    width: 100%;
    padding: 10px;
  }
`;

const Location = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  width: 50%;
  text-align: center;
  margin: 5% 0 2% 25%;

  @media (max-width: 768px) {
    width: 90%;
    margin: 20px auto;
  }
`;

const Timer = styled.div`
  display: flex;
  gap: 11%;
  margin: 0 0 3% 25%;

  @media (max-width: 768px) {
    gap: 5%;
    margin: 20px auto;
    justify-content: center;
  }
`;

const TimeItem = styled.div`
  text-align: center;
`;

const Number = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: rgba(245, 130, 49, 1);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Label = styled.div`
  font-size: 0.8rem;
`;

const RightCard = styled.div`
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.4);

  backdrop-filter: blur(8px);
  padding: 40px;
  margin: 0 9% 0 0;
  border-radius: 30px;
  max-width: 550px;
  color: white;

  @media (max-width: 768px) {
    order: 2;
    margin: 20px auto;
    width: 90%;
    max-width: none;
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 0.01px;
  background-color: rgba(255, 255, 255, 1);
  border: 0.01px solid rgba(255, 255, 255, 1);
  margin: 2% 0 3% 0;
`;

const CardItem = styled.div`
  margin-bottom: 20px;
`;

const Icon = styled.div`
  display: inline-block;
  margin-right: 10px;
  transform: translateY(10px);
`;

const FormIcon = styled.img`
  height: 30px;
  width: 30px;
`;

const P = styled.p`
  width: 85%;
  margin: 0 0 0 8%;
  font-family: Montserrat;
  a {
    color: #1c399d;
    text-decoration: none;
    /* font-family:Montserrat ; */
    font-weight: 600;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    width: 85%;
    margin: 0 0 0 8%;
  }
`;

const IconLocation = styled.img`
  margin-right: 10px;
`;

const RegisterButton = styled.button`
  background: rgba(245, 130, 49, 1);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: darkorange;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 16px;
  }
`;

// Countdown Functionality
const calculateTimeLeft = () => {
  const eventDate = new Date("2025-03-20T00:00:00");
  const difference = eventDate - new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

const Hero = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <HeroSection>
      <LeftContainer>
        <Title>2025 NCRP Annual Meeting</Title>
        <Subtitle>
          Integration of Physics, Biology and Epidemiology in Radiation Risk
          Assessment
        </Subtitle>

        <Location>
          <div>
            <IconLocation src="../../location.png" alt="Location icon" />
          </div>
          Hyatt Regency Bethesda, One Bethesda Metro Center, 7400 Wisconsin Ave,
          Bethesda, MD 20814
        </Location>
        <Timer>
          <TimeItem>
            <Number>{timeLeft.days || 0}</Number>
            <Label>Days</Label>
          </TimeItem>
          <TimeItem>
            <Number>{timeLeft.hours || 0}</Number>
            <Label>Hrs</Label>
          </TimeItem>
          <TimeItem>
            <Number>{timeLeft.minutes || 0}</Number>
            <Label>Min</Label>
          </TimeItem>
          <TimeItem>
            <Number>{timeLeft.seconds || 0}</Number>
            <Label>Sec</Label>
          </TimeItem>
        </Timer>
      </LeftContainer>

      <RightCard>
        <CardItem>
          <Icon>
            <FormIcon src="../../conduct.png" alt="" />
          </Icon>
          <strong> NCRP CODE OF CONDUCT</strong>
          <P>
            As a 2025 NCRP Annual Meeting participant, you are agreeing to
            adhere to the Meeting Code of Conduct.
            <a
              href="https://ncrp.civdigital.com/wp-content/uploads/2023/01/cid3DC0D750-660F-4E95-9A47-D0A69FE6B9DF.pdf"
              download
              target="_blank"
              rel="noreferrer"
            >
              Attendee Code of Conduct.
            </a>
               Your agreement to adhere to the Code is acknowledged by your
            registration. Any violation of the Code of Conduct will result in
            the revocation of your meeting access with no refund. Thank you for
            respecting your colleagues, peers and clients.
          </P>
        </CardItem>
        <LineDiv />
        <CardItem>
          <Icon>
            <FormIcon src="../../register.png" alt="" />
          </Icon>
          <strong>REGISTRATION CANCELLATION POLICY</strong>
          <P>
          Requests for cancellations must be made in writing by March 17, 2025; a $25 cancellation fee will apply. 
          Cancellations after this date are nonrefundable. Submit all cancellation requests to 
          <a href="mailto:laura.atwell@ncrponline.org"> laura.atwell@ncrponline.org</a>. NCRP regrets that refunds will not be given for no-shows.
          </P>
        </CardItem>
        <LineDiv />
        <CardItem>
          <Icon>
            <FormIcon src="../../clause.png" alt="" />
          </Icon>
          <strong>CONSENT CLAUSE</strong>
          <P>
          Registration and attendance at, or participation in, NCRP meetings and other activities constitutes an agreement by the registrant to the use and distribution (both now and in the future) of the registrant or attendee’s image or voice in recordings, both live and on-demand, photographs, videotapes, electronic reproductions, and audiotapes of such events and activities by NCRP.
          </P>
        </CardItem>

        <RegisterButton onClick={() => navigate("/register")}>
          Register Now
        </RegisterButton>
        <p>
          Integration of Physics, Biology and Epidemiology in Radiation Risk
          Assessment
        </p>
      </RightCard>
    </HeroSection>
  );
};

export default Hero;
