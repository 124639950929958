// FailModal.js
import React from 'react';
import styled from 'styled-components';
import Modal from './modal.jsx';

const FailContent = styled.div`
  text-align: center;
  color: red;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;

const CrossIcon = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 4px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const FailModal = ({ isOpen, onClose, message }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <FailContent>
      <IconWrapper>
        <CrossIcon />
      </IconWrapper>
      <h2>Failure!</h2>
      {message && <p>{message}</p>}
    </FailContent>
  </Modal>
);

export default FailModal;
