export const US_STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const CITIES_BY_STATE = {
  AL: [
    { value: "Birmingham", label: "Birmingham" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Mobile", label: "Mobile" },
  ],
  AK: [
    { value: "Anchorage", label: "Anchorage" },
    { value: "Fairbanks", label: "Fairbanks" },
    { value: "Juneau", label: "Juneau" },
  ],
  AZ: [
    { value: "Phoenix", label: "Phoenix" },
    { value: "Tucson", label: "Tucson" },
    { value: "Mesa", label: "Mesa" },
  ],
  AR: [
    { value: "Little Rock", label: "Little Rock" },
    { value: "Fayetteville", label: "Fayetteville" },
    { value: "Fort Smith", label: "Fort Smith" },
  ],
  CA: [
    { value: "Los Angeles", label: "Los Angeles" },
    { value: "San Francisco", label: "San Francisco" },
    { value: "San Diego", label: "San Diego" },
  ],
  CO: [
    { value: "Denver", label: "Denver" },
    { value: "Colorado Springs", label: "Colorado Springs" },
    { value: "Aurora", label: "Aurora" },
  ],
  CT: [
    { value: "Hartford", label: "Hartford" },
    { value: "New Haven", label: "New Haven" },
    { value: "Stamford", label: "Stamford" },
  ],
  DE: [
    { value: "Wilmington", label: "Wilmington" },
    { value: "Dover", label: "Dover" },
    { value: "Newark", label: "Newark" },
  ],
  FL: [
    { value: "Miami", label: "Miami" },
    { value: "Orlando", label: "Orlando" },
    { value: "Tampa", label: "Tampa" },
  ],
  GA: [
    { value: "Atlanta", label: "Atlanta" },
    { value: "Savannah", label: "Savannah" },
    { value: "Augusta", label: "Augusta" },
  ],
  HI: [
    { value: "Honolulu", label: "Honolulu" },
    { value: "Hilo", label: "Hilo" },
    { value: "Kailua", label: "Kailua" },
  ],
  ID: [
    { value: "Boise", label: "Boise" },
    { value: "Nampa", label: "Nampa" },
    { value: "Meridian", label: "Meridian" },
  ],
  IL: [
    { value: "Chicago", label: "Chicago" },
    { value: "Aurora", label: "Aurora" },
    { value: "Naperville", label: "Naperville" },
  ],
  IN: [
    { value: "Indianapolis", label: "Indianapolis" },
    { value: "Fort Wayne", label: "Fort Wayne" },
    { value: "Evansville", label: "Evansville" },
  ],
  IA: [
    { value: "Des Moines", label: "Des Moines" },
    { value: "Cedar Rapids", label: "Cedar Rapids" },
    { value: "Davenport", label: "Davenport" },
  ],
  KS: [
    { value: "Wichita", label: "Wichita" },
    { value: "Overland Park", label: "Overland Park" },
    { value: "Kansas City", label: "Kansas City" },
  ],
  KY: [
    { value: "Louisville", label: "Louisville" },
    { value: "Lexington", label: "Lexington" },
    { value: "Bowling Green", label: "Bowling Green" },
  ],
  LA: [
    { value: "New Orleans", label: "New Orleans" },
    { value: "Baton Rouge", label: "Baton Rouge" },
    { value: "Shreveport", label: "Shreveport" },
  ],
  ME: [
    { value: "Portland", label: "Portland" },
    { value: "Lewiston", label: "Lewiston" },
    { value: "Bangor", label: "Bangor" },
  ],
  MD: [
    { value: "Baltimore", label: "Baltimore" },
    { value: "Frederick", label: "Frederick" },
    { value: "Rockville", label: "Rockville" },
  ],
  MA: [
    { value: "Boston", label: "Boston" },
    { value: "Worcester", label: "Worcester" },
    { value: "Springfield", label: "Springfield" },
  ],
  MI: [
    { value: "Detroit", label: "Detroit" },
    { value: "Grand Rapids", label: "Grand Rapids" },
    { value: "Ann Arbor", label: "Ann Arbor" },
  ],
  MN: [
    { value: "Minneapolis", label: "Minneapolis" },
    { value: "St. Paul", label: "St. Paul" },
    { value: "Rochester", label: "Rochester" },
  ],
  MS: [
    { value: "Jackson", label: "Jackson" },
    { value: "Gulfport", label: "Gulfport" },
    { value: "Southaven", label: "Southaven" },
  ],
  MO: [
    { value: "Kansas City", label: "Kansas City" },
    { value: "St. Louis", label: "St. Louis" },
    { value: "Springfield", label: "Springfield" },
  ],
  MT: [
    { value: "Billings", label: "Billings" },
    { value: "Missoula", label: "Missoula" },
    { value: "Great Falls", label: "Great Falls" },
  ],
  NE: [
    { value: "Omaha", label: "Omaha" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Bellevue", label: "Bellevue" },
  ],
  NV: [
    { value: "Las Vegas", label: "Las Vegas" },
    { value: "Reno", label: "Reno" },
    { value: "Henderson", label: "Henderson" },
  ],
  NH: [
    { value: "Manchester", label: "Manchester" },
    { value: "Nashua", label: "Nashua" },
    { value: "Concord", label: "Concord" },
  ],
  NJ: [
    { value: "Newark", label: "Newark" },
    { value: "Jersey City", label: "Jersey City" },
    { value: "Paterson", label: "Paterson" },
  ],
  NM: [
    { value: "Albuquerque", label: "Albuquerque" },
    { value: "Las Cruces", label: "Las Cruces" },
    { value: "Rio Rancho", label: "Rio Rancho" },
  ],
  NY: [
    { value: "New York City", label: "New York City" },
    { value: "Buffalo", label: "Buffalo" },
    { value: "Rochester", label: "Rochester" },
  ],
  NC: [
    { value: "Charlotte", label: "Charlotte" },
    { value: "Raleigh", label: "Raleigh" },
    { value: "Greensboro", label: "Greensboro" },
  ],
  ND: [
    { value: "Fargo", label: "Fargo" },
    { value: "Bismarck", label: "Bismarck" },
    { value: "Grand Forks", label: "Grand Forks" },
  ],
  OH: [
    { value: "Columbus", label: "Columbus" },
    { value: "Cleveland", label: "Cleveland" },
    { value: "Cincinnati", label: "Cincinnati" },
  ],
  OK: [
    { value: "Oklahoma City", label: "Oklahoma City" },
    { value: "Tulsa", label: "Tulsa" },
    { value: "Norman", label: "Norman" },
  ],
  OR: [
    { value: "Portland", label: "Portland" },
    { value: "Salem", label: "Salem" },
    { value: "Eugene", label: "Eugene" },
  ],
  PA: [
    { value: "Philadelphia", label: "Philadelphia" },
    { value: "Pittsburgh", label: "Pittsburgh" },
    { value: "Allentown", label: "Allentown" },
  ],
  RI: [
    { value: "Providence", label: "Providence" },
    { value: "Warwick", label: "Warwick" },
    { value: "Cranston", label: "Cranston" },
  ],
  SC: [
    { value: "Columbia", label: "Columbia" },
    { value: "Charleston", label: "Charleston" },
    { value: "North Charleston", label: "North Charleston" },
  ],
  SD: [
    { value: "Sioux Falls", label: "Sioux Falls" },
    { value: "Rapid City", label: "Rapid City" },
    { value: "Aberdeen", label: "Aberdeen" },
  ],
  TN: [
    { value: "Nashville", label: "Nashville" },
    { value: "Memphis", label: "Memphis" },
    { value: "Knoxville", label: "Knoxville" },
  ],
  TX: [
    { value: "Houston", label: "Houston" },
    { value: "Dallas", label: "Dallas" },
    { value: "Austin", label: "Austin" },
  ],
  UT: [
    { value: "Salt Lake City", label: "Salt Lake City" },
    { value: "West Valley City", label: "West Valley City" },
    { value: "Provo", label: "Provo" },
  ],
  VT: [
    { value: "Burlington", label: "Burlington" },
    { value: "South Burlington", label: "South Burlington" },
    { value: "Rutland", label: "Rutland" },
  ],
  VA: [
    { value: "Virginia Beach", label: "Virginia Beach" },
    { value: "Norfolk", label: "Norfolk" },
    { value: "Chesapeake", label: "Chesapeake" },
  ],
  WA: [
    { value: "Seattle", label: "Seattle" },
    { value: "Spokane", label: "Spokane" },
    { value: "Tacoma", label: "Tacoma" },
  ],
  WV: [
    { value: "Charleston", label: "Charleston" },
    { value: "Huntington", label: "Huntington" },
    { value: "Morgantown", label: "Morgantown" },
  ],
  WI: [
    { value: "Milwaukee", label: "Milwaukee" },
    { value: "Madison", label: "Madison" },
    { value: "Green Bay", label: "Green Bay" },
  ],
  WY: [
    { value: "Cheyenne", label: "Cheyenne" },
    { value: "Casper", label: "Casper" },
    { value: "Laramie", label: "Laramie" },
  ],
};
