import React from "react";
import { Navigate } from "react-router-dom";

// Define a PrivateRoute component
const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("NCRPadmin"); // Check if token exists

  // If authenticated, render the children (protected component), otherwise redirect to login page
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
