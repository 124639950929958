import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./components/index";
import MultiStepForm from "./components/forms";
import AdminR from "./components/Admin/register";
import Admin from "./components/Admin/Admin";
import Payment from "./components/paypal";
import PrivateRoute from "./components/protectedRoute";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="register" element={<MultiStepForm />} />
        <Route path="login" element={<AdminR />} />
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="payment" element={<Payment amount={250} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
