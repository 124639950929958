import styled from "styled-components";
const size = {
  xs: "319px",
  sm: "639px",
  md: "809px",
  lg: "1079px",
  xl: "1279px",
  xxl: "1535px",
};

const devices = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
};

export const StyledMain = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => props.height || "420px"};
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0px 0px 0px 0px;
  background: #1c399d;
  position: relative;
  @media ${devices.xxl} {
    height: ${(props) => props.xxlHeight || "400px"};
    padding: 2rem 0;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    min-height: ${(props) => props.smallHeight || "650px"};
    padding: 2rem 0;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    min-height: ${(props) => props.smallerHeight || "800px"};
    padding: 0rem 0;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    min-height: ${(props) => props.mobileHeight || "900px"};
    padding: 2rem 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    min-height: ${(props) => props.xsHeight || "750px"};
    padding: 2rem 0;
  }
`;
export const StyledDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => props.margin || "30px"};
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    margin-bottom: ${(props) => props.marginB || "10px"};
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1080px) {
    margin-bottom: 30px;
  }

  @media ${devices.sm} {
    margin-bottom: 0;
    padding: 2rem 0 0 0;
  }
  @media only screen and (min-width: 550px) and (max-width: 640px) {
    margin-bottom: 0;
    padding: 4rem 0 0 0;
  }
  @media only screen and (min-width: 450px) and (max-width: 549px) {
    margin-bottom: 0;
    padding: 4rem 0 0 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 449px) {
    margin-bottom: 0;
    padding: 6rem 0 0 0;
  }
`;
export const StyledSectionA = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 1079px) and (max-width: 1280px) {
    flex-direction: column;
    gap: 0px;
  }
  @media ${devices.lg} {
    flex-direction: column;
    gap: 60px;
  }
  @media ${devices.sm} {
    flex-direction: column;
    gap: 60px;
  }
`;
export const StyledFirst = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 55%;
    transform: translateY(-69px);
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    margin-top: -17%;
  }
  @media only screen and (min-width: 444px) and (max-width: 640px) {
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    margin-top: -30%;
  }
  @media only screen and (min-width: 320px) and (max-width: 443px) {
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    margin-top: -50%;
  }
`;
export const StyledSecond = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  width: 66%;
  gap: 35px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 100%;
    margin: auto;
    align-items: flex-start;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    width: 100%;
    margin: auto;
    align-items: flex-start;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    width: 100%;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100%;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const StyledA1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  gap: 8px;
  @media ${devices.xxl} {
    gap: 10px;
  }
  @media ${devices.xl} {
    gap: 20px;
  }
  @media ${devices.lg} {
    gap: 15px;
  }
  @media ${devices.sm} {
    gap: 10px;
  }
`;
export const StyledIconSec = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    justify-content: center;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
  }

  @media ${devices.sm} {
    width: 80%;
  }
  @media only screen and (min-width: 410px) and (max-width: 480px) {
    width: 64%;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    width: 49%;
  }
`;
export const StyledIcon = styled.img`
  width: 370px;
  height: 79.856px;
  flex-shrink: 0;
  // background:white;
  @media ${devices.sm} {
    width: 186.999px;
    height: 40px;
  }
`;
export const StyledH4 = styled.h4`
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 24.32px */
  letter-spacing: 0.16px;
  text-align: left;
  @media ${devices.xxl} {
    font-size: 15px;
  }
  @media ${devices.xl} {
    font-size: 12px;
  }
  @media ${devices.lg} {
    font-size: 10px;
  }
  @media ${devices.sm} {
    font-size: 10px;
  }
`;
export const StyledP = styled.p`
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%; /* 24.32px */
  letter-spacing: 0.16px;
  text-align: left;
  margin: 0;

  & span {
    display: block;
    color: var(--color-gray-0, #fff);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.16px;
    text-decoration-line: underline;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    text-align: center;
    width: 100%;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    text-align: center;
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    text-align: left;
  }
  @media ${devices.sm} {
    text-align: left;
    font-size: 12px;
  }
`;
export const StyledA2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 41%;
  margin: auto;
  cursor: pointer;
  @media only screen and (min-width: 1081px) and (max-width: 1536px) {
    gap: 15px;
    height: 216px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    gap: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    width: 100%;
    gap: 8px;
  }
  @media ${devices.sm} {
    width: 100%;
    gap: 8px;
  }
  @media ${devices.sm} {
    width: 100%;
  }
`;
export const StyledTitle = styled.p`
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%; /* 28px */
  letter-spacing: 0.16px;
  margin: 0;

  @media ${devices.sm} {
    font-size: 11px;
  }
`;
export const StyledList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const StyledListItems = styled.li`
  width: fit-content;
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  letter-spacing: 0.16px;
  text-align: left;
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.7s ease-in-out;
    transform-origin: bottom left;
  }

  &:hover::before {
    visibility: visible;
    width: 100%;
  }
  &.active::before {
    visibility: visible;
    width: 100%;
    background-color: var(--color-active, #fff); /* Adjust color if needed */
  }
  @media ${devices.sm} {
    font-size: 12px;
  }
`;
export const StyledA3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5%;
  cursor: pointer;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    gap: 15px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 15px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1080px) {
    gap: 10px;
  }
  @media ${devices.sm} {
    gap: 8px;
  }
`;
export const StyledIconRow = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledIcon1 = styled.div`
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: var(--color-gray-0, #fff);
`;
export const StyledTag = styled.div`
  display: flex;
  width: 80%;
  height: 214px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #f1f9ff;
  position: relative;
  bottom: 21px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    bottom: 10px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    bottom: 100px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    flex-direction: column;
    height: auto;
    transform: translateY(-39%);
    bottom: -85px;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    transform: translateY(-39%);
    bottom: 75px;
  }
  @media ${devices.sm} {
    flex-direction: column;
    height: auto;
    transform: translateY(-39%);
    width: 90%;
    padding: 32px;
  }
`;
export const StyledText = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  @media only screen and (min-width: 641px) and (max-width: 1080px) {
    align-items: center;
  }

  @media ${devices.sm} {
    width: 100%;
  }
`;
export const StyledH2 = styled.h2`
  color: #1c399d;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.32px;
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    font-size: 24px;
  }
  @media ${devices.sm} {
    width: 105%;
  }
`;
export const StyledP1 = styled.p`
  position: relative;
  padding-left: 10px;
  color: #414141;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.4%; /* 21.024px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px; /* Width of the vertical line */
    background: #f58231;
    /* Color of the vertical line */
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    font-size: 12px;
  }
  @media ${devices.sm} {
    font-size: 15px;
  }
`;
export const StyledP2 = styled.p`
  color: #404040;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  align-self: stretch;
  text-align: left;
  white-space: pre-line;
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    text-align: center;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    font-size: 12px;
    text-align: center;
  }
  @media ${devices.sm} {
    text-align: center;
  }
`;
export const StyledButonSec = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 17%;
  }
  @media only screen and (min-width: 641px) and (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  width: 160px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 128px;
  background: var(--Primary, #1c399d);
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.4%; /* 21.024px */
  letter-spacing: 0.16px;
  border: none;
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    padding: 10px 12px;
  }
`;
export const StyledLine = styled.div`
  margin-top: 3%;
  width: 100%;
  height: 0.7px;
  align-self: stretch;
  background: var(--color-gray-0, #fff);
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    margin-top: -3%;
  }
`;
export const StyledSectionB = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateY(6px);
  @media ${devices.xxl} {
    transform: translateY(15px);
  }
  @media ${devices.xl} {
    transform: translateY(10px);
  }
  @media ${devices.lg} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    transform: translateY(6px);
  }

  @media ${devices.sm} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    transform: translateY(6px);
  }
`;
export const StyledP3 = styled.p`
  color: var(--color-gray-0, #fff);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%; /* 18.24px */
  letter-spacing: 0.12px;
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    font-size: 10px;
  }
  @media ${devices.sm} {
    font-size: 6px;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  @media ${devices.xxl} {
    width: 35%;
  }
  @media ${devices.lg} {
    width: 60%;
  }
  @media ${devices.sm} {
    width: 60%;
  }
`;
export const StyledThird = styled.div`
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    display: flex;
    width: 55%;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    display: flex;
    width: 70%;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    width: 40%;
    width: 100%;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledBoth = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media only screen and (min-width: 641px) and (max-width: 900px) {
    gap: 14%;
  }
  @media ${devices.sm} {
    gap: 5%;
  }
`;
export const StyledIcons = styled.div`
  display: flex;
  gap: 10px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: -8%;
  }
  @media only screen and (min-width: 901px) and (max-width: 1080px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 900px) {
  }
`;
export const IconFirst = styled.div`
  @media ${devices.sm} {
    width: 41px;
    height: 41px;
  }
`;
